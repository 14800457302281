import { useTranslation } from "react-i18next";
import RawmForm from "./RawmForm";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { isEmptyObject, slang } from "../utils/Utils";
import SupplyForm from "./SupplyForm";

const RawmAdmin = function (props) {

    const { t } = useTranslation();

    const table_titles = [
        "id",
        t("general.name"),
        t("supply.supplier") + " nb",
        "options"
    ];


    return <div>

        <Header user={props.user} />

        <div className="col-lg-9 mx-auto">

            <AdminPage
                title={slang("Les Matériaux", "Materials")}
                endpoint="raw_material"
                CustomForm={RawmForm}
                table_titles={table_titles}
                filt1="id"
                filt2="rawName"
                itemsPerPage={10}
                RowContent={({ elt }) => {
                    return <>
                        <th scope="row">{elt.id}</th>
                        <td>{elt.rawName}</td>
                        <td className="text-center">{elt.nb_suppliers}</td>
                    </>
                }}
                DetailsSection={({ elt }) => {

                    if (isEmptyObject(elt)) return <></>

                    return <div  style={{marginTop:"50px"}}> 
                    
                    <h2 className="display-3" style={{marginBottom:"30px"}}>{elt.rawName}</h2>
                    <SupplyForm
                        idRawMaterial={elt.id}
                    />
                    </div>
                }
                }
            />

        </div>
    </div>
}

export default RawmAdmin;