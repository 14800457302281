import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyInput, FormTitleGeneric, handleSubmitGeneric, slang, MySpinningSelectSearch, format_date_num_nohour, MyValidationButton } from "../utils/Utils";

const OpRowForm = function ({ current_item, refresh_list }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(current_item);

    const [itemToSave, setItemToSave] = useState({
        opDate: (!bool_modify || !current_item.opDate)
            ? format_date_num_nohour(Date.now())
            : format_date_num_nohour(current_item.opDate),
        details: bool_modify ? current_item.details : "",
        amount: bool_modify ? current_item.amount : 0,
        idOpCategory: bool_modify ? current_item.idOpCategory : null,
        idUser: bool_modify ? current_item.idUser : null,
        idSale: bool_modify ? current_item.idSale : null,
        idSupply: bool_modify ? current_item.idSupply : null,
        idDebt: bool_modify ? current_item.idDebt : null,
    })

    const refresh_itemToSave = function (myName, myValue) {

        const newItem = { ...itemToSave, [myName]: myValue }

        // Gestion des cas qui impactent d'autres cases quand on les remplit

        setItemToSave(newItem);
    }

    return <div>
        <FormTitleGeneric bool_modify={bool_modify}
            name={current_item?.details}
            id={current_item?.id}
        />

        <form className="col-lg-8 col-md-10 mx-auto mb-3"
            onSubmit={(e) => handleSubmitGeneric(e, "op_row", itemToSave, current_item?.id, refresh_list, bool_modify)}>

            <MyInput
                myName="opDate"
                myLabel="date"
                myType="date"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={1}
            />

            <MySpinningSelectSearch
                spinnerText={slang("Chargement Catégories", "Loading Categories")}
                searchRefreshItemToSave={refresh_itemToSave}
                searchName="idOpCategory"
                searchLabel={t("op.category")}
                searchEndPoint="op_category_for_select"
                searchIdGroup={2}
            />

            <MyInput
                myName="details"
                myLabel="details"
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={3}
                placeholder={current_item?.details}
            />

            <MyInput
                myName="amount"
                myLabel={t("op.amount")}
                myType="int"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={4}
                placeholder={current_item?.amount}
            />

            <br/>

            <MySpinningSelectSearch
                spinnerText={slang("Chargement Employés", "Loading Employees")}
                searchRefreshItemToSave={refresh_itemToSave}
                searchName="idUser"
                searchLabel={t("op.user")}
                searchEndPoint="user_for_select"
                searchIdGroup={5}
            />

            <MySpinningSelectSearch
                spinnerText={slang("Chargement Commandes", "Loading Orders")}
                searchRefreshItemToSave={refresh_itemToSave}
                searchName="idSale"
                searchLabel={t("op.sale")}
                searchEndPoint="sale_for_select"
                searchIdGroup={6}
            />

            <MySpinningSelectSearch
                spinnerText={slang("Chargement Catégories", "Loading Categories")}
                searchRefreshItemToSave={refresh_itemToSave}
                searchName="idSupply"
                searchLabel={t("supply.supply")}
                searchEndPoint="supply_for_select"
                searchIdGroup={7}
            />

            <MySpinningSelectSearch
                spinnerText={slang("Chargement Dettes", "Loading Debts")}
                searchRefreshItemToSave={refresh_itemToSave}
                searchName="idDebt"
                searchLabel={t("op.debt")}
                searchEndPoint="debt_for_select"
                searchIdGroup={8}
            />

            <MySpinningSelectSearch
                spinnerText={slang("Chargement Créances", "Loading Receivables")}
                searchRefreshItemToSave={refresh_itemToSave}
                searchName="idReceivable"
                searchLabel={t("op.receivable")}
                searchEndPoint="receivable_for_select"
                searchIdGroup={9}
            />

            {/* Validation */}

            <MyValidationButton bool_modify={bool_modify} />

        </form>

        

    </div>
}

export default OpRowForm;