
import error404 from "./images/error404.png";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import HomePage from "./general/HomePage";
import LoginPage from "./general/LoginPage";
import LogoutPage from "./general/LogoutPage";

import { useEffect, useState } from "react";
import CustomerAdmin from "./customers/CustomerAdmin";
import SaleAdmin from "./sales/SaleAdmin";
import ProductAdmin from "./products/ProductAdmin";
import RawmAdmin from "./rawm/RawmAdmin";
import SupplierAdmin from "./suppliers/SupplierAdmin";
import TodoAdmin from "./todos/TodoAdmin";
import UserAdmin from "./users/UserAdmin";
import DebtAdmin from "./debt/DebtAdmin";
import ReceivableAdmin from "./receivable/ReceivableAdmin";
import OpSetAdmin from "./op_set/OpSetAdmin";
import OpRowAdmin from "./operations/OpRowAdmin";
import ChartPage from "./general/ChartPage";
import { MySpinner, slang } from "./utils/Utils";

const proxyUrl = require("../package.json").proxy;

function NoMatch() {
    let location = useLocation();

    return (
        <div className="m-3 p-3 text-center justify-content-center">
            <img className="fullImg" src={error404} alt="Error 404" />
            <h3>
                No match for
                <code>{location.pathname}</code>
            </h3>
            <a className="m-3 p-3 btn btn-lg btn-outline-primary" href="/">Home / Aller à l'Accueil</a>
        </div>
    );
}

function App() {

    const [user,
        setUser] = useState({ email: "" });

    const [userRefresh,
        setUserRefresh] = useState(false);

    const toggleRefresh = function () {
        setUserRefresh(!userRefresh)
    }

    // Fetch User, if authenticated

    useEffect(() => {

        const abortController = new AbortController();

        const fetchUserInfo = async () => {

            fetch(proxyUrl + "/get_user/", {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                signal: abortController.signal,
            })
                .then(response => response.json())
                .then(data => {

                    setUser(data)
                })
                .catch((error) => {
                    if (error.name === 'AbortError') {
                        // Request was cancelled
                        console.log('Request Aborted!');
                        return;
                    }
                })
        };
        fetchUserInfo();

        return () => {
            abortController.abort(); // Cancel the request if component unmounts
        };
    }, [userRefresh]);

    // priv 1: superuser 2: operations  3: recordInfo  4: stats

    const priv = {
        superuser: 1,
        operations: 2,
        recordInfo: 2,
        stats: 2,
    }

    const GetAuthRoute = function ({ p, comp }) {

        const defaultElement = <MySpinner show={true} myText={slang("Chargement","Loading")} />

        switch (p) {
            case priv.superuser:
                if (user.privSuperuser === 1) return comp
                return defaultElement

            case priv.operations:
                if (user.privSuperuser === 1 || user.privOperations === 1) return comp
                return defaultElement

            case priv.recordInfo:
                if (user.privSuperuser === 1 || user.privRecordInfo === 1) return comp
                return defaultElement

            case priv.stats:
                if (user.privSuperuser === 1 || user.privDisplayStats === 1) return comp
                return defaultElement

            default:
                return defaultElement
        }
    }

    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<HomePage user={user} />} />

                <Route path="/chart" element={<GetAuthRoute p={priv.stats} comp={<ChartPage user={user} />} />} />

                <Route path="/login" element={<LoginPage user={user} refreshUser={toggleRefresh} />} />

                <Route path="/logout" element={<LogoutPage user={user} refreshUser={toggleRefresh} />} />

                <Route path="/customer_admin" element={<GetAuthRoute p={priv.recordInfo} comp={<CustomerAdmin user={user} />} />} />

                <Route path="/sale_admin" element={<GetAuthRoute p={priv.recordInfo} comp={<SaleAdmin user={user} />} />} />

                <Route path="/product_admin" element={<GetAuthRoute p={priv.recordInfo} comp={<ProductAdmin user={user} />} />} />

                <Route path="/rawm_admin" element={<GetAuthRoute p={priv.recordInfo} comp={<RawmAdmin user={user} />} />} />

                <Route path="/supplier_admin" element={<GetAuthRoute p={priv.recordInfo} comp={<SupplierAdmin user={user} />} />} />

                <Route path="/todo_admin" element={<GetAuthRoute p={priv.recordInfo} comp={<TodoAdmin user={user} />} />} />

                <Route path="/user_admin" element={<GetAuthRoute p={priv.superuser} comp={<UserAdmin user={user} />} />} />

                <Route path="/debt_admin" element={<GetAuthRoute p={priv.operations} comp={<DebtAdmin user={user} />} />} />

                <Route path="/receivable_admin" element={<GetAuthRoute p={priv.operations} comp={<ReceivableAdmin user={user} />} />} />

                <Route path="/op_set_admin" element={<GetAuthRoute p={priv.operations} comp={<OpSetAdmin user={user} />} />} />

                <Route path="/op_row_admin" element={<GetAuthRoute p={priv.operations} comp={<OpRowAdmin user={user} />} />} />

                <Route path="*" element={<NoMatch />} />
            </Routes>
        </Router>
    );
}

export default App;
