import { useTranslation } from "react-i18next";
import SaleForm from "./SaleForm";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { digitGroup, format_date_nohour, isEmptyObject, MySpinningSelectSearch, sale_statuses, slang } from "../utils/Utils";
import { useState } from "react";
import SaleDetailsForm from "./SaleDetailsForm";

const SaleAdmin = function (props) {

    const { t } = useTranslation();

    const [filtCustomer, setFiltCustomer] = useState(0)

    const table_titles = [
        "id",
        // t("op.dueOn"),
        t("op.customer"),
        t("general.status"),
        t("op.amount"),
        t("sale.advance"),
        t("op.balance"),
        "options"
    ];

    return <div>

        <Header user={props.user} />

        <div className="col-lg-11 mx-auto">

            <div className="col-8" style={{ margin: "50px 10px 0 10px" }}>

                <MySpinningSelectSearch
                    spinnerText={slang("Chargement Liste Clients", "Loading Client List")}
                    searchRefreshItemToSave={(myName, myValue) => { setFiltCustomer(parseInt(myValue)) }}
                    searchName="idCustomer"
                    searchLabel={t("op.customer")}
                    searchEndPoint="customerfor_select"
                    searchIdGroup={1}
                />
            </div>

            <AdminPage
                title={slang("Commandes", "Orders")}
                endpoint="sale"
                CustomForm={SaleForm}
                table_titles={table_titles}
                filt1="id"
                filt2="customer"
                filt3="amount"
                itemsPerPage={8}
                globalFilters={[
                    // titre du filtre, field, function qui renvoie true si l'élt évalué doit s'afficher

                    [t("general.filter") + slang(" Client", " Customer") + " " + filtCustomer, "idCustomer", (elt) => (elt === filtCustomer)],
                    [sale_statuses[0][1], "saleStatus", (elt) => (elt === 0)],
                    [sale_statuses[1][1], "saleStatus", (elt) => (elt === 1)],
                    [sale_statuses[2][1], "saleStatus", (elt) => (elt === 2)],
                    [sale_statuses[3][1], "saleStatus", (elt) => (elt === 3)],
                    [sale_statuses[4][1], "saleStatus", (elt) => (elt === 4)],
                ]}
                RowContent={({ elt }) => {
                    return <>
                        <th scope="row">{elt.id}</th>
                        {/* <td>{format_date_nohour(elt.dueOn)}</td> */}
                        <td>{(elt.customer == null) ? <span className="small">{slang("Pas indiqué", "Not yet filled")}</span> : elt.customer + "(" + elt.idCustomer + ")"}</td>
                        <td className="text-center">
                            {sale_statuses[elt.saleStatus][1]}
                        </td>
                        <td>{digitGroup(elt.amount)}</td>
                        <td>{digitGroup(elt.advance)}</td>
                        <td className={elt.amount > elt.advance ? "text-success" : "text-secondary"}>{digitGroup(elt.amount - elt.advance)}</td>
                    </>
                }}
                DetailsSection={({ elt }) => {

                    if (isEmptyObject(elt)) return <></>

                    return <div className="fs-2 bg-light p-3">
                        <h3 className="fs-2 my-3">{slang("Commande ", "Order id_") + elt.id + " : " + (elt.customer ?? "") + " "} {digitGroup(elt.amount)}</h3>
                        <p className="fst-italic">
                            {elt.details}
                        </p>
                        <p>
                            {t("op.lastModif") + " " + format_date_nohour(elt.lastModification) + "\n"}
                        </p>
                        <p>
                            {t("op.recordedOn") + " " + format_date_nohour(elt.recordedOn)}
                        </p>

                        <SaleDetailsForm idSale={elt.id} />
                    </div>
                }}
            />

        </div>
    </div>
}

export default SaleAdmin;