import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyInput, FormTitleGeneric, handleSubmitGeneric, MySpinningSelectSearch, slang, MySelect, FormTitleRelated, MyTextArea, sale_statuses, format_date_num_nohour, MyValidationButton, digitGroup } from '../utils/Utils';

import OpRowLinkedAdmin from '../operations/OpRowLinkedAdmin';

const SaleForm = function ({ current_item, refresh_list }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(current_item);

    const [tab, setTab] = useState(1);

    const handleChoice = function (choice) {
        setTab(choice);
    };

    const [itemToSave, setItemToSave] = useState({
        details: bool_modify ? current_item.details : "",
        amount: bool_modify ? current_item.amount : 0,
        dueOn: (!bool_modify || !current_item.dueOn)
            ? format_date_num_nohour(Date.now())
            : format_date_num_nohour(current_item.dueOn),
        saleStatus: bool_modify ? current_item.saleStatus : 0,
        advance: bool_modify ? current_item.advance : 0,
        idCustomer: bool_modify ? current_item.idCustomer : null,
    })

    const refresh_itemToSave = function (myName, myValue) {

        const newItem = { ...itemToSave, [myName]: myValue }

        // Gestion des cas qui impactent d'autres cases quand on les remplit

        setItemToSave(newItem);
    }


    return <>

        {bool_modify &&
            <div className="btn-group mt-3 mb-3" role="group" aria-label="Navigation buttons">

                <button type="button" className={"btn btn-lg fs-3 mb-3 fw-bold text-bold btn-" + (tab === 1 ? "primary" : "outline-dark")} onClick={() => handleChoice(1)}>{(t("op.sale") + " id_" + current_item?.id).toUpperCase()}</button>
                {/* 
                <button type="button" className={"btn btn-lg fs-3 mb-3 btn-" + (tab === 2 ? "primary" : "outline-dark")} onClick={() => handleChoice(2)}>EVENTS</button>
 */}
                <button type="button" className={"btn btn-lg fs-3 mb-3 btn-" + (tab === 3 ? "primary" : "outline-dark")} onClick={() => handleChoice(3)}>OPERATIONS</button>
            </div>
        }

        {tab === 1 && <>

            <FormTitleGeneric bool_modify={bool_modify}
                name={current_item?.amount}
                id={current_item?.id}
            />

            <form className="col-lg-8 col-md-10 mx-auto mb-3"
                onSubmit={(e) => handleSubmitGeneric(e, "sale", itemToSave, current_item?.id, refresh_list, bool_modify)}>

                <MyInput
                    myName="amount"
                    myLabel={t("op.amount")}
                    myType="Number"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={1}
                    func={parseInt}
                    placeholder={current_item?.amount}
                />
                {/* 
                <MyInput
                    myName="dueOn"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={2}
                    myLabel={t("op.dueOn")}
                    myType="date"
                />
 */}
                <MyInput
                    myName="advance"
                    myLabel={t("sale.advance")}
                    myType="Number"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={3}
                    func={parseInt}
                    placeholder={current_item?.advance}
                />

                <div className={"row m-0 " + (current_item?.amount > current_item?.advance ? "text-success" : "text-secondary")}>

                    <p className="col-4 fs-2">
                    </p>
                    <p className="col-4 fs-2">
                        {t("op.balance") + " = "} {digitGroup(parseInt(itemToSave.amount - itemToSave.advance))}
                    </p>
                </div>


                <MySpinningSelectSearch
                    spinnerText={slang("Chargement Liste Clients", "Loading Client List")}
                    searchRefreshItemToSave={refresh_itemToSave}
                    searchName="idCustomer"
                    searchLabel={t("op.customer")}
                    searchEndPoint="customerfor_select"
                    searchIdGroup={5}
                />

                <MyTextArea
                    myName="details"
                    myLabel="details"
                    myType="Text"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={4}
                    maxLength={1000}
                />

                {/* 0: en attente 1: lancée 2: prêt 3: livré 4: récupéré */}
                <MySelect
                    data={sale_statuses}
                    myLabel={t("general.status")}
                    myName="saleStatus"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={6}
                    func={parseInt}
                />

                {/* Validation */}

                <MyValidationButton bool_modify={bool_modify} />

            </form>



        </>}

        {(bool_modify && tab > 1) &&
            <FormTitleRelated
                name={t("op.sale") + " " + current_item?.amount}
                id={current_item?.id}
            />
        }
        {/* 
        {(bool_modify && tab === 2) &&

            <SaleEventsForm idSale={current_item?.id} />
        }
 */}
        {(bool_modify && tab === 3) &&

            <OpRowLinkedAdmin linked_id={current_item?.id} linked_name="idSale" />
        }
    </>
}

export default SaleForm;