import { useCallback, useEffect, useState } from "react";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import { Line } from "react-chartjs-2";

import { digitGroup, displaySnackbar, format_date_nohour, format_date_num_nohour, my_fetch_get, MyInput, MySelectMultiple, slang } from "../utils/Utils";
import { ResponsiveTable } from "../utils/ResponsiveTable";
// eslint-disable-next-line
import Chart from 'chart.js/auto';

Chart.defaults.font.size = 28;

const proxyUrl = require("../../package.json").proxy;

const ChartPage = function (props) {

    const { t } = useTranslation();

    var date = new Date();

    const [displayFilter, setDisplayFilter] = useState(false)

    const [by, setBy] = useState("day")
    const [dateMin, setDateMin] = useState(format_date_num_nohour(new Date(date.getFullYear(), date.getMonth(), 1)))
    const [dateMax, setDateMax] = useState('')
    const [op_cat, setOp_cat] = useState([])
    const [op_set, setOp_set] = useState([])

    const [op_cat_list, setOp_cat_list] = useState([])
    const [op_set_list, setOp_set_list] = useState([])

    const [dataResult, setDataResult] = useState([]) // result
    const [dataTable, setDataTable] = useState([]) // result_table

    const endpoint = "op_stats"

    const getDataLabels = function (data) {
        let result = [];

        for (let i = 0; i < data.length; i++) {

            switch (by) {
                case 'day':
                    result.push(format_date_nohour(data[i]["opDate"]));
                    break;
                case 'month':
                    result.push(data[i]["opYear"] + "_" + data[i]["opMonth"]);

                    break;
                case 'year':
                    result.push(data[i]["opYear"]);
                    break;

                default:
                    break;
            }

        }
        return result
    }

    const getDataValues = function (data, item) {
        let result = [];

        for (let i = 0; i < data.length; i++) {

            switch (item) {
                case "income":
                    result.push(parseInt(data[i]["income"]));
                    break;
                case "expense":
                    result.push(parseInt(data[i]["expense"]));
                    break;
                case "result":
                    result.push(parseInt(data[i]["result"]));
                    break;

                default:
                    break;
            }

        }
        return result
    }

    // fetch op_set list and op_cat list

    useEffect(() => {

        (async () => {
            setOp_set_list(await my_fetch_get("/op_set"))
            setOp_cat_list(await my_fetch_get("/op_category"))
        })();

    }, [])

    // fetch expense, income and result data

    const getData = useCallback(async (current_item) => {

        const url = proxyUrl + "/" + endpoint + "/" + by + "/" + current_item;

        // Logique pour enregistrer

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                date_min: dateMin,
                date_max: dateMax,
                op_cat: op_cat,
                op_set: op_set
            }),
        })

        const data = await response.json()

        if (response.status === 200) {

            if (current_item === 'result') setDataResult(data)
            if (current_item === 'result_table') setDataTable(data)
        } else {

            displaySnackbar(slang("Une erreur est survenue", "An error occured"), url, "warning")
        }

    }, [by, dateMin, dateMax, op_cat, op_set])

    useEffect(() => {

        getData("result")
        getData("result_table")

    }, [getData]);

    const table_titles = [
        "setName",
        "codeName",
        "date info",
        t("op.income"),
        t("op.expense"),
        t("op.result"),
    ]


    // Refresh Selection
    const refresh_selection = function (myName, myValue) {

        switch (myName) {
            case 'date_min':
                setDateMin(myValue)
                break;
            case 'date_max':
                setDateMax(myValue)
                break;
            case 'op_cat':
                setOp_cat(myValue)
                break;
            case 'op_set':
                setOp_set(myValue)
                break;

            default:
                break;
        }
    }

    return <div>

        <Header user={props.user} />

        <div className="col-lg-10 mx-auto">

            <h1 className="py-3 display-2">Stats</h1>

            <button
                className="btn btn-lg btn-outline-secondary fs-3 mx-2"
                type="button"
                onClick={() => { setDisplayFilter(!displayFilter) }}
            >
                {displayFilter ? slang("Masquer Filtres", "Hide Filters") : slang("Afficher Filtres", "Show Filters")}
            </button>

            {displayFilter &&
                <div id="filterDiv" className="bg-light col text-center">

                    <div className="row py-3">

                        <div className="col-5 btn-group mx-auto my-3 py-3" role="group" aria-label="Navigation buttons">

                            <button type="button" className={"btn btn-lg fs-3 mb-3 btn-" + (by === "day" ? "primary" : "outline-dark")} onClick={() => setBy("day")}>DAY</button>
                            <button type="button" className={"btn btn-lg fs-3 mb-3 btn-" + (by === "month" ? "primary" : "outline-dark")} onClick={() => setBy("month")}>MONTH</button>
                            <button type="button" className={"btn btn-lg fs-3 mb-3 btn-" + (by === "year" ? "primary" : "outline-dark")} onClick={() => setBy("year")}>YEAR</button>

                        </div>

                        <div className="row col-7 mx-auto">
                            <MyInput
                                myName="date_min"
                                refresh_itemToSave={refresh_selection}
                                idGroupe={1}
                                myLabel={"Date_min"}
                                myType="date"
                                func={String}
                            />

                            <MyInput
                                myName="date_max"
                                refresh_itemToSave={refresh_selection}
                                idGroupe={2}
                                myLabel={"Date_max"}
                                myType="date"
                                func={String}
                            />
                        </div>

                    </div>


                    <div className="row mx-2" id="chart_filter">

                        <MySelectMultiple
                            myName="op_set"
                            refresh_itemToSave={refresh_selection}
                            idGroupe={4}
                            myLabel={slang("Groupes", "Sets")}
                            data={op_set_list.map((d) => ([d.id, d.opSetName?.substring(0, 50)]))}
                            func={parseInt}
                        />

                        <MySelectMultiple
                            myName="op_cat"
                            refresh_itemToSave={refresh_selection}
                            idGroupe={3}
                            myLabel={slang("Catégories", "Categories")}
                            data={op_cat_list.map((d) => ([d.id, d.code + " - " + d.fullName?.substring(0, 25)]))}
                            func={parseInt}
                        />

                    </div>

                </div>
            }

            {/* Graphique */}

            <div className="card my-3 py-3 mx-2">

                <Line
                    data={{
                        labels: getDataLabels(dataResult),
                        datasets: [
                            {
                                label: t("op.expense"),
                                data: getDataValues(dataResult, "expense"),
                                tension: 0.2,
                            },
                            {
                                label: t("op.income"),
                                data: getDataValues(dataResult, "income"),
                                tension: 0.2,
                            },
                            {
                                label: t("op.result"),
                                data: getDataValues(dataResult, "result"),
                                tension: 0.2,
                            },
                        ],
                    }}
                />
            </div>

            {/* Tableau */}

            <div className="mx-2" style={{ marginTop: "50px" }}>

                <ResponsiveTable
                    itemsPerPage={10}
                    listOfItems={dataTable}
                    table_titles={table_titles}
                    Row={({ elt }) => {

                        const GetDateInfo = function ({ elt }) {

                            let comp = null;

                            switch (by) {
                                case 'day':
                                    comp = <td className="text-center">{format_date_nohour(elt.opDate)}</td>
                                    break;
                                case 'month':
                                    comp = <td className="text-center">
                                        {elt.opYear}_{elt.opMonth}
                                    </td>
                                    break;
                                case 'year':
                                    comp = <td className="text-center">{elt.opYear}</td>
                                    break;

                                default:
                                    break;
                            }

                            return comp;
                        }

                        return <tr
                            key={elt.id}
                            onClick={() => { }}
                        >
                            <>
                                <td className="text-center">{elt.setName}</td>
                                <td className="text-center">{elt.codeName}</td>
                                <GetDateInfo elt={elt} />
                                <td>{digitGroup(elt.income)}</td>
                                <td>{digitGroup(elt.expense)}</td>
                                <td>{digitGroup(elt.sumAmount)}</td>
                            </>
                        </tr>
                    }}
                    LastRow={({ data }) => {

                        const GetTotal = function (field_name) {
                            let total = 0;

                            for (let i = 0; i < data.length; i++) {
                                total += parseInt(data[i][field_name]);
                            }

                            return digitGroup(total);
                        }

                        return <tr className="fw-bold fs-2">
                            <td colSpan={3}>Grand Total</td>
                            <td>{GetTotal("income")}</td>
                            <td>{GetTotal("expense")}</td>
                            <td>{GetTotal("sumAmount")}</td>
                        </tr>
                    }
                    }
                />
            </div>

        </div>
    </div>
}

export default ChartPage;