import { useTranslation } from "react-i18next";
import ReceivableForm from "./ReceivableForm";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { digitGroup, format_date_nohour, slang } from "../utils/Utils";

const ReceivableAdmin = function (props) {

    const { t } = useTranslation();

    const table_titles = [
        "id",
        "details",
        "date",
        t("op.amount"),
        t("sale.advance"),
        t("op.balance"),
        "options"
    ];

    return <div>

        <Header user={props.user} />

        <div className="col-lg-9 mx-auto">

            <AdminPage
                title={slang("Créances", "Receivables")}
                endpoint="receivable"
                CustomForm={ReceivableForm}
                table_titles={table_titles}
                filt1="id"
                filt2="details"
                filt3="amount"
                itemsPerPage={10}
                globalFilters={[
                    // titre du filtre, field, function qui renvoie true si l'élt évalué doit s'afficher

                    [t("general.filter") + slang(" Non Soldé", " Not settled"), "balance", (elt) => (elt !== 0)]
                ]}
                RowContent={({ elt }) => {
                    return <>
                        <th scope="row">{elt.id}</th>
                        <td>{elt.details}</td>
                        <td>{format_date_nohour(elt.dueOn)}</td>
                        <td>{digitGroup(elt.amount)}</td>
                        <td>{digitGroup(elt.advance)}</td>
                        <td className={elt.amount > elt.advance ? "text-success" : "text-danger"}>{digitGroup(elt.amount - elt.advance)}</td>
                    </>
                }}
                LastRow={({ data }) => {

                    const GetTotal = function (field_name) {
                        let total = 0;

                        for (let i = 0; i < data?.length; i++) {
                            total += parseInt(data[i][field_name]);
                        }

                        return total;
                    }

                    let totalAmount = GetTotal("amount")
                    let totalAdvance = GetTotal("advance")

                    return <tr className="fw-bold fs-2">
                        <td colSpan={3}>Grand Total</td>
                        <td>{digitGroup(totalAmount)}</td>
                        <td>{digitGroup(totalAdvance)}</td>
                        <td className={totalAmount > totalAdvance ? "text-success" : "text-danger"}>{digitGroup(totalAmount - totalAdvance)}</td>
                    </tr>
                }
                }
            />

        </div>
    </div>
}

export default ReceivableAdmin;