import { useTranslation } from "react-i18next";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { digitGroup, format_date_nohour, isEmptyObject, slang } from "../utils/Utils";
import OpRowForm from "./OpRowForm";

const OpRowAdmin = function (props) {

    const { t } = useTranslation();

    // id INT NOT NULL AUTO_INCREMENT PRIMARY KEY,
    // code VARCHAR(10),
    // fullName VARCHAR(300),
    // isExpense BOOLEAN DEFAULT false,
    // concernsSalary BOOLEAN DEFAULT false,
    // concernsSale BOOLEAN DEFAULT false,
    // concernsSupply BOOLEAN DEFAULT false

    const table_titles = [
        "id",
        "Date",
        "Code",
        "Details",
        t("op.amount"),
        "options"
    ];

    return <div>

        <Header user={props.user} />

        <div className="col-lg-9 mx-auto">

            <AdminPage
                title={slang("Opérations", "Transactions")}
                endpoint="op_row"
                CustomForm={OpRowForm}
                table_titles={table_titles}
                filt1="amount"
                filt2="op_code"
                filt3="details"
                itemsPerPage={10}
                RowContent={({ elt }) => {

                    function getSuccessClassIfIncome() {
                        
                        if (!elt.isExpense) return "text-success"

                        return ""
                    }

                    return <>
                        <th scope="row" className={getSuccessClassIfIncome()}>{elt.id}</th>
                        <td className={getSuccessClassIfIncome()}>{format_date_nohour(elt.opDate)}</td>
                        <td className={getSuccessClassIfIncome()}>{elt.op_code}</td>
                        <td className={getSuccessClassIfIncome()}>{elt.details}</td>
                        <td className={getSuccessClassIfIncome()}>{digitGroup(elt.amount)}</td>
                    </>
                }}
                DetailsSection={({ elt }) => {

                    if (isEmptyObject(elt)) return <></>

                    return <div className={"fs-2 bg-light p-3 " + (!elt.isExpense ? "text-success" : "")}>
                        <h3 className="fs-2 my-3">{slang("Détails ligne ", "Row details id_") + elt.id + " _ " + elt.op_code}</h3>
                        <p>{(elt.idUser === null) ? "" : t("op.user") + " " + elt.idUser}</p>
                        <p>{(elt.idSale === null) ? "" : t("op.sale") + " " + elt.idSale}</p>
                        <p>{(elt.idSupply === null) ? "" : t("supply.supply") + " " + elt.idSupply}</p>
                        <p>{(elt.idDebt === null) ? "" : t("op.debt") + " " + elt.idDebt}</p>

                        <p>{t("op.recordedOn") + " " + format_date_nohour(elt.recordedOn)}</p>

                        <p>{t("op.lastModif") + " " + format_date_nohour(elt.lastModification)}</p>
                    </div>
                }}
            />

        </div>
    </div>
}

export default OpRowAdmin;