import { useEffect, useState } from 'react';
import { EditableSubForm } from '../utils/EditableSubForm';
import { useTranslation } from 'react-i18next';
import { my_fetch_get } from '../utils/Utils';

const SupplierRawmForm = function ({ idSupplier }) {

    const { t } = useTranslation();

    const [items, setItems] = useState([]);
    
    const [rawmList, setRawmList] = useState([]);

    const [supplierRawmRefresh,
        setSupplierRawmRefresh] = useState(true);

    const toggleSupplierRawmRefresh = function () {
        setSupplierRawmRefresh(!supplierRawmRefresh)
    }

    const endpoint = "supplier_raw"

    // fetch supplier_raw

    useEffect(() => {

        (async () => {
            setItems(await my_fetch_get("/" + endpoint + "/" + idSupplier))
        })();

    }, [idSupplier, supplierRawmRefresh])

    // fetch raw_materials

    useEffect(() => {

        (async () => {
            setRawmList(await my_fetch_get("/raw_material_for_select"))
        })();

    }, [])

    

    // column_label, fieldName, editable, dataType, showTotal, dataSource
    // types : text, boolean, int, float, date, select_int

    const options = [
            {
                columnLabel: t("supply.rawm"),
                fieldName: "idRaw",
                editable: true,
                dataType: "select_int",
                showTotal: false,
                dataSource: rawmList,
                titleAlign: "center",
            },
    ]

    return <EditableSubForm endpoint={endpoint}
        items={items}
        options={options}
        parent_criteria={{ idSupplier: idSupplier }}
        toggleRefresh={toggleSupplierRawmRefresh}
    />
}

export default SupplierRawmForm;