import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyCheckBox, MyInput, FormTitleGeneric, handleSubmitGeneric, format_date_num_nohour, MyValidationButton } from '../utils/Utils';
import OpRowLinkedAdmin from '../operations/OpRowLinkedAdmin';
import TodoLinkedAdmin from './TodoLinkedAdmin';

const UserForm = function ({ current_item, refresh_list }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(current_item);

    const [tab, setTab] = useState(1);

    const handleChoice = function (choice) {
        setTab(choice);
    };

    const [itemToSave, setItemToSave] = useState({
        userName: bool_modify ? current_item.userName : "",
        hiredOn: (!bool_modify || !current_item.hiredOn)
            ? format_date_num_nohour(Date.now())
            : format_date_num_nohour(current_item.hiredOn),
        email: bool_modify ? current_item.email : "",
        tel1: bool_modify ? current_item.tel1 : "",
        tel2: bool_modify ? current_item.tel2 : "",
        isTel1WA: bool_modify ? current_item.isTel1WA : false,
        isTel2WA: bool_modify ? current_item.isTel2WA : false,
        customerAddress: bool_modify ? current_item.customerAddress : "",
        details: bool_modify ? current_item.details : "",
        privSuperuser: bool_modify ? current_item.isTel2WA : false,
        privSettings: bool_modify ? current_item.isTel2WA : false,
        privOperations: bool_modify ? current_item.isTel2WA : false,
        privRecordInfo: bool_modify ? current_item.isTel2WA : false,
        privDisplayStats: bool_modify ? current_item.isTel2WA : false,
    })

    const refresh_itemToSave = function (myName, myValue) {

        const newItem = { ...itemToSave, [myName]: myValue }

        // Gestion des cas qui impactent d'autres cases quand on les remplit

        setItemToSave(newItem);
    }

    return <>

        {bool_modify &&
            <div className="btn-group mt-3 mb-3" role="group" aria-label="Navigation buttons">

                <button type="button" className={"btn btn-lg fs-3 mb-3 fw-bold text-bold btn-" + (tab === 1 ? "primary" : "outline-dark")} onClick={() => handleChoice(1)}>{(t("op.user") + " id_" + current_item?.id).toUpperCase()}</button>

                <button type="button" className={"btn btn-lg fs-3 mb-3 btn-" + (tab === 2 ? "primary" : "outline-dark")} onClick={() => handleChoice(2)}>OPERATIONS</button>

                <button type="button" className={"btn btn-lg fs-3 mb-3 btn-" + (tab === 3 ? "primary" : "outline-dark")} onClick={() => handleChoice(3)}>{t("user.tasks").toUpperCase()}</button>
            </div>
        }

        {tab === 1 && <>
            <FormTitleGeneric bool_modify={bool_modify}
                name={current_item?.userName}
                id={current_item?.id}
            />


            <form className="col-lg-8 col-md-10 mx-auto mb-3"
                onSubmit={(e) => handleSubmitGeneric(e, "user", itemToSave, current_item?.id, refresh_list, bool_modify)}>

                <MyInput
                    myName="userName"
                    myLabel={t("general.name")}
                    myType="text"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={1}
                    maxLength={150}
                    placeholder={current_item?.userName}
                />

                <MyInput
                    myName="email"
                    myLabel="email"
                    myType="text"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={2}
                    maxLength={150}
                    placeholder={current_item?.email}
                />

                <MyInput
                    myName="hiredOn"
                    myLabel={t("user.hiredOn")}
                    myType="date"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={3}
                />

                <MyInput
                    myName="tel1"
                    myLabel="Tel1"
                    myType="text"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={4}
                    maxLength={20}
                    placeholder={current_item?.tel1}
                />

                <MyCheckBox
                    myName="isTel1WA"
                    myLabel="Tel1 Whatsapp"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={5}
                />

                <MyInput
                    myName="tel2"
                    myLabel="Tel2"
                    myType="text"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={6}
                    maxLength={20}
                    placeholder={current_item?.tel2}
                />

                <MyCheckBox
                    myName="isTel2WA"
                    myLabel="Tel2 Whatsapp"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={7}
                />

                <MyInput
                    myName="details"
                    myLabel="details"
                    myType="text"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={8}
                    placeholder={current_item?.details}
                />

                <MyCheckBox
                    myName="privSuperuser"
                    myLabel="privSuperuser"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={9}
                />

                <MyCheckBox
                    myName="privSettings"
                    myLabel="privSettings"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={10}
                />

                <MyCheckBox
                    myName="privOperations"
                    myLabel="privOperations"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={11}
                />

                <MyCheckBox
                    myName="privRecordInfo"
                    myLabel="privRecordInfo"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={12}
                />

                <MyCheckBox
                    myName="privDisplayStats"
                    myLabel="privDisplayStats"
                    refresh_itemToSave={refresh_itemToSave}
                    idGroupe={13}
                />


                {/* Validation */}

                <MyValidationButton bool_modify={bool_modify} />

            </form>

            

        </>
        }

        {(bool_modify && tab === 2) &&

            <OpRowLinkedAdmin linked_id={current_item?.id} linked_name="idUser" />
        }

        {(bool_modify && tab === 3) &&

            <TodoLinkedAdmin linked_id={current_item?.id} linked_name="idUser" />
        }
    </>
}

export default UserForm;