import { useTranslation } from "react-i18next";
import ProductForm from "./ProductForm";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { digitGroup, my_fetch_get, slang } from "../utils/Utils";
import { EditableSubForm } from "../utils/EditableSubForm";
import { useEffect, useState } from "react";

const ProductAdmin = function (props) {

    const { t } = useTranslation();

    const [items, setItems] = useState([]);

    const [productionRefresh,
        setProductionRefresh] = useState(true);

    const toggleProductionRefresh = function () {
        setProductionRefresh(!productionRefresh)
    }

    const endpoint = "production"

    // fetch supplier_raw via le endpoint raw_supplier

    useEffect(() => {

        (async () => {
            setItems(await my_fetch_get("/" + endpoint))
        })();

    }, [productionRefresh])

    const table_titles = [
        "id",
        t("general.name"),
        t("op.salePrice"),
        // t("op.costPrice"),
        "options"
    ];

    // column_label, fieldName, editable, dataType, showTotal, dataSource
    // types : text, boolean, int, float, date, select_int

    const options = [
        {
            columnLabel: "date",
            fieldName: "productionDate",
            editable: true,
            dataType: "date",
            showTotal: false,
            dataSource: null,
            titleAlign: "center",
        },
        {
            columnLabel: t("sale.qty"),
            fieldName: "qty",
            editable: true,
            dataType: "int",
            showTotal: false,
            dataSource: null,
            titleAlign: "center",
        },
        {
            columnLabel: "details",
            fieldName: "details",
            editable: true,
            dataType: "text",
            showTotal: false,
            dataSource: null,
            titleAlign: "center",
        },
    ]

    return <div>

        <Header user={props.user} />

        <div className="col-lg-9 mx-auto">

            <AdminPage
                title={slang("Produits", "Products")}
                endpoint="product"
                CustomForm={ProductForm}
                table_titles={table_titles}
                filt1="id"
                filt2="productName"
                filt3="salePrice"
                itemsPerPage={8}
                RowContent={({ elt }) => {
                    return <>
                        <th scope="row">{elt.id}</th>
                        <td>{elt.productName}</td>
                        <td className="text-center">{digitGroup(elt.salePrice)}</td>
                        {/* <td className="text-center">{digitGroup(elt.costPrice)}</td> */}
                    </>
                }}
                DetailsSection={({ elt }) => {

                    

                    return <>

                        <h1 className="py-3 display-2">Production - {elt?.productName ?? "No product"}</h1>

                        <EditableSubForm 
                            endpoint={endpoint}
                            items={items}
                            options={options}
                            parent_criteria={{ idProduct: elt.id }}
                            toggleRefresh={toggleProductionRefresh}
                            globalFilters={[
                                // titre du filtre, field, function qui renvoie true si l'élt évalué doit s'afficher
                
                                [t("general.filter") + slang(" Produit", " Product") + " " + elt?.productName, "idProduct", (e) => (e === elt.id)],
                            ]}
                        />
                    </>
                }}
            />

        </div>
    </div>
}

export default ProductAdmin;