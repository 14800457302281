import { useTranslation } from "react-i18next";
import SupplierForm from "./SupplierForm";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { GetWhatsAppIcon, isEmptyObject, slang } from "../utils/Utils";
import SupplierRawmForm from "./Supplier_rawmForm";

const SupplierAdmin = function (props) {

    const { t } = useTranslation();

    const table_titles = [
        "id",
        t("general.name"),
        "tel1",
        "tel2",
        "options"
    ];

    return <div>

        <Header user={props.user} />

        <div className="col-lg-9 mx-auto">

            <AdminPage
                title={slang("Fournisseurs", "Suppliers")}
                endpoint="supplier"
                CustomForm={SupplierForm}
                table_titles={table_titles}
                filt1="supplierName"
                filt2="tel1"
                filt3="tel2"
                itemsPerPage={10}
                RowContent={({ elt }) => {
                    return <>
                        <th scope="row">{elt.id}</th>
                        <td>{elt.supplierName}</td>
                        <td>
                            {elt.tel1}
                            &nbsp;
                            <GetWhatsAppIcon isTelWA={elt.isTel1WA} />
                        </td>
                        <td>
                            {elt.tel2}
                            &nbsp;
                            <GetWhatsAppIcon isTelWA={elt.isTel1WA} />
                        </td>
                    </>
                }}
                DetailsSection={({ elt }) => {

                    if (isEmptyObject(elt)) return <></>

                    return <div className="fs-2 bg-light p-3">
                        <h3 className="fs-2 my-3">
                            {slang("Ligne ", "Row id_") + elt.id + " _ " + elt.supplierName}
                        </h3>

                        <p>
                            {"Details: " + elt.details}
                        </p>

                        <SupplierRawmForm idSupplier={elt.id} />
                    </div>
                }}
            />

        </div>
    </div>
}

export default SupplierAdmin;