import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyInput, handleSubmitGeneric, FormTitleGeneric, MySpinningSelectSearch, slang, MyCheckBox, format_date_num_nohour, MyValidationButton } from '../utils/Utils';

const TodoForm = function ({ current_item, refresh_list }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(current_item);

    const [itemToSave, setItemToSave] = useState({
        task: bool_modify ? current_item.task : "",
        dueOn: (!bool_modify || !current_item.dueOn)
            ? format_date_num_nohour(Date.now())
            : format_date_num_nohour(current_item.dueOn),
        idUser: bool_modify ? current_item.idUser : null,
        done: bool_modify ? current_item.done : 0,
    })

    const refresh_itemToSave = function (myName, myValue) {

        const newItem = { ...itemToSave, [myName]: myValue }

        // Gestion des cas qui impactent d'autres cases quand on les remplit

        setItemToSave(newItem);
    }


    return <div>

        <FormTitleGeneric bool_modify={bool_modify}
            name={current_item?.task}
            id={current_item?.id}
        />

        <form className="col-lg-8 col-md-10 mx-auto mb-3"
            onSubmit={(e) => handleSubmitGeneric(e, "todo", itemToSave, current_item?.id, refresh_list, bool_modify)}>

            <MyInput
                myName="task"
                myLabel={t("user.task")}
                myType="Text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={1}
                placeholder={current_item?.task}
            />

            <MyInput
                myName="dueOn"
                myLabel={t("op.dueOn")}
                myType="date"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={2}
            />

            <MyCheckBox
                myName="done"
                myLabel={t("op.done")}
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={3}
            />

            <MySpinningSelectSearch
                spinnerText={slang("Chargement Employés", "Loading Employees")}
                searchRefreshItemToSave={refresh_itemToSave}
                searchName="idUser"
                searchLabel={t("op.employee")}
                searchEndPoint="user_for_select"
                searchIdGroup={4}
            />

            {/* Validation */}

            <MyValidationButton bool_modify={bool_modify} />

        </form>

        
    </div >
}

export default TodoForm;