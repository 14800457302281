import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyCheckBox, MyInput, FormTitleGeneric, handleSubmitGeneric, slang, MySpinningSelectSearch, MyValidationButton } from "../utils/Utils";

const OperationForm = function ({ current_item, refresh_list }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(current_item);

    const [itemToSave, setItemToSave] = useState({
        code: bool_modify ? current_item.code : "",
        fullName: bool_modify ? current_item.fullName : "",
        isExpense: bool_modify ? current_item.isExpense : false,
        idSet: bool_modify ? current_item.idSet : null,
    })

    const refresh_itemToSave = function (myName, myValue) {

        const newItem = { ...itemToSave, [myName]: myValue }

        // Gestion des cas qui impactent d'autres cases quand on les remplit

        setItemToSave(newItem);
    }

    return <div>
        <FormTitleGeneric bool_modify={bool_modify}
            name={current_item?.fullName}
            id={current_item?.id}
        />

        <form className="col-lg-8 col-md-10 mx-auto mb-3"
            onSubmit={(e) => handleSubmitGeneric(e, "op_category", itemToSave, current_item?.id, refresh_list, bool_modify)}>

            <MyInput
                myName="code"
                myLabel="code"
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={1}
                maxLength={10}
                placeholder={current_item?.code}
            />

            <MyInput
                myName="fullName"
                myLabel="fullName"
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={2}
                placeholder={current_item?.fullName}
            />

            <MySpinningSelectSearch
                spinnerText={slang("Chargement Catégories", "Loading Categories")}
                searchRefreshItemToSave={refresh_itemToSave}
                searchName="idSet"
                searchLabel={t("op.set")}
                searchEndPoint="op_set_for_select"
                searchIdGroup={3}
            />

            <MyCheckBox
                myName="isExpense"
                myLabel={t("op.isExpense")}
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={4}
            />

            {/* Validation */}

            <MyValidationButton bool_modify={bool_modify} />

        </form>

        
    </div>
}

export default OperationForm;