
import Header from "./Header";
import { useEffect } from "react";

const proxyUrl = require("../../package.json").proxy;

const LogoutPage = function (props) {

    useEffect(() => {

        const abortController = new AbortController();

        const fetchUserInfo = async () => {

            fetch(proxyUrl + "/logout/", {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include",
                signal: abortController.signal,
            })
                .then(response => response.json())
                .then(
                    (res) => {
                        if (res.status === 200) {
                            props.refreshUser();

                            document.getElementById("redirect_to_homepage").click()
                        }
                    })
                .catch((error) => {
                    if (error.name === 'AbortError') {
                        // Request was cancelled
                        console.log('Request Aborted!', 'LogoutPage');
                        return;
                    }
                })
        };
        fetchUserInfo();

        return () => {
            abortController.abort(); // Cancel the request if component unmounts
        };
    }, [props]);

    return <div>
        <Header user={props.user} />

        <div className="col-lg-8 mx-auto">

            <div className="container mt-5">
                <h1>Logout </h1>

                <p className="display-1">Good Bye !</p>

                <a href="/" hidden id="redirect_to_homepage">whatsapp</a>
            </div >
        </div >



    </div >
}

export default LogoutPage;