
import Header from "./Header";
import { useState } from "react";
import { Navigate } from 'react-router-dom';
import { MySpinner, my_fetch_post, slang } from "../utils/Utils";

let choice = 0;

const LoginPage = function (props) {

    const [credentials,
        setCredentials] = useState({
            email: "",
            password: ""
        })

    const [isLogged,
        setIsLogged] = useState(false)

    const [message,
        setMessage] = useState("")

    const [showSpinner, setShowSpinner] = useState(false)

    const handleSubmit = async e => {

        e.preventDefault();

        if (choice === 1 && !credentials.password) return;  // refuse d'enregistrer les mots de passe vides

        let url = (choice === 0 ? "/login" : "/signup")

        let response

        setShowSpinner(true)

        try {
            response = await my_fetch_post(url, { email: credentials.email, password: credentials.password })

        } catch (error) {
            console.log("error", error)
            setMessage(error);
            return
        }

        setShowSpinner(false)

        if (response.status === 500) {

            setMessage(slang("Impossible de joindre le Serveur, veuillez vérifier votre connexion internet", "Server is not reachable, maybe you're not connected to internet"))
            return
        }

        if (response.status === 401) {

            setMessage(slang("Mail ou Mot de passe incorrect", "Mail or Password incorrect"))
            return
        }

        if (response.status === 200) {

            setIsLogged(true);
            props.refreshUser();
        }

    }

    return <div>
        <Header user={props.user} />

        <div className="container-sm mt-5">

            {message &&
                <p style={{ color: "red" }}>{message}</p>
            }

            {!isLogged &&
                <div className="col-sm-8 col-lg-6 mx-auto">
                    <h1 className="text-center display-2">Connexion</h1>
                    <div className="card">
                        <div className="card-body fs-3">

                            <form onSubmit={handleSubmit}>
                                <div className="form-group mb-1 mt-3">
                                    <label htmlFor="email">Email</label>
                                    <input
                                        autoFocus
                                        type="email"
                                        className="form-control fs-2"
                                        name="email"
                                        autoComplete="on"
                                        onChange={e => setCredentials({ ...credentials, email: e.target.value })} />
                                </div>
                                <div className="form-group mb-3 mt-3">
                                    <label htmlFor="password">Password</label>
                                    <input
                                        type="password"
                                        className="form-control fs-3"
                                        name="password"
                                        autoComplete="on"
                                        onChange={e => setCredentials({ ...credentials, password: e.target.value })} />
                                </div>
                                <div className="col-10 gap-2 d-flex mx-auto mt-3">

                                    <MySpinner show={showSpinner} />

                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-lg col-6 fs-3"
                                        onClick={() => {
                                            choice = 0
                                        }}>
                                        Login
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-outline-primary btn-lg col-6 fs-3"
                                        onClick={() => {
                                            choice = 1
                                        }}>
                                        Register
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            }
            {isLogged && <Navigate to="/" />}
        </div >

    </div >
}

export default LoginPage;