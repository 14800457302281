// import { useTranslation } from "react-i18next";
import { useState } from "react";
import LanguageSelector from "../utils/LanguageSelector";
import MyToast from '../utils/MyToast';
import { slang } from "../utils/Utils";

const Header = function (props) {

    // const {t} = useTranslation()

    const isLogged = Boolean(props.user?.email);

    let current_username = "No_name"

    const [showMenu, setShowMenu] = useState(false);

    const hide_or_show_menu = function () {
        setShowMenu(!showMenu);
    }

    const GetLabel = function ({ text }) {

        return <li className="text-secondary fs-3 border-bottom border-secondary m-2" style={{ marginTop: "20px" }}><label>{text}</label></li>
    }

    const GetLink = function ({ href, text, gras=false }) {

        return <li><a className={"nav-link active pe-3" + (gras ? " fw-bolder" : "")} href={href}>{text}</a></li>
    }

    if (props.user?.userName) current_username = props.user.userName.substring(0, 15)

    return <div className="header fs-1 sticky-top">

        <nav className="navbar .navbar-expand navbar-light bg-light p-2">

            <div className="col-4">

                <a className="navbar-brand" href="/">
                    <img style={{ height: "4.5rem", width: "auto" }} src="/images/logo_sapinou_3.png" alt="Logo Sapinou Style" />
                </a>
                {showMenu && <LanguageSelector />}
            </div>


            <div className="text-end" style={{ width: "60%" }}>

                <button
                    type="button"
                    className="btn btn-lg border-secondary fs-1"
                    onClick={hide_or_show_menu}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                {showMenu &&
                    <ul className="navbar-nav me-auto mb-2 mb-md-0 fs-1">

                        {!isLogged &&
                            <li><a className="nav-link active text-primary" href="/login">Login</a></li>
                        }
                        {isLogged && <>
                            <li><a className="nav-link active text-primary" href="/logout">{current_username + " Logout"}</a></li>

                            <table>
                                <tbody>
                                    <tr>
                                        <td className="align-top">
                                            {(props.user?.privSuperuser || props.user?.privRecordInfo) ?
                                                <>
                                                    <GetLabel text="" />

                                                    <GetLink href="/sale_admin" text={slang("Commandes", "Orders")} gras={true} />
                                                    <GetLink href="/product_admin" text={slang("Produits", "Products")} />
                                                    <GetLink href="/customer_admin" text={slang("Clients", "Customers")} />
                                                </>
                                                :
                                                <></>

                                            }

                                            {(props.user?.privSuperuser || props.user?.privDisplayStats) ?
                                                <li><a className="nav-link active pe-3 text-success fw-bolder" href="/chart">Stats</a></li>
                                                :
                                                <></>
                                            }
                                        </td>
                                        <td> </td>

                                        {(props.user?.privSuperuser || props.user?.privOperations) ?
                                            <td className="align-top">
                                                <GetLabel text="" />

                                                <GetLink href="/op_row_admin" text={slang("Opérations", "Transactions")} gras={true} />
                                                <GetLink href="/debt_admin" text={slang("Dettes", "Debts")} />
                                                <GetLink href="/receivable_admin" text={slang("Créances", "Receivables")} />
                                                <GetLink href="/op_set_admin" text={slang("Groupes", "Groups")} />
                                            </td>
                                            :
                                            <></>
                                        }
                                    </tr>
                                    <tr>
                                        {(props.user?.privSuperuser || props.user?.privRecordInfo) ?
                                            <td className="align-top">
                                                <GetLabel text="" />

                                                <GetLink href="/rawm_admin" text={slang("Matières Premières", "Raw Materials")} />
                                                <GetLink href="/supplier_admin" text={slang("Fournisseurs", "Suppliers")} />
                                            </td>
                                            :
                                            <></>
                                        }
                                        <td> </td>
                                        <td className="align-top">
                                            <GetLabel text="" />

                                            {(props.user?.privSuperuser) &&
                                                <GetLink href="/user_admin" text={slang("Employés", "Employees")} />
                                            }
                                            {(props.user?.privSuperuser || props.user?.privRecordInfo) ?
                                                <GetLink href="/todo_admin" text={slang("Tâches", "Todos")} />
                                                :
                                                <></>
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>


                        </>
                        }

                    </ul>
                }
            </div>


        </nav>

        <MyToast />
    </div>
}

export default Header;