import { useTranslation } from "react-i18next";
import UserForm from "./UserForm";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { displaySnackbar, format_date_nohour, GetWhatsAppIcon, isEmptyObject, MyBooleanLabel, slang } from "../utils/Utils";


const proxyUrl = require("../../package.json").proxy;

const UserAdmin = function (props) {

    const { t } = useTranslation();

    const table_titles = [
        "id",
        t("general.name"),
        "email",
        t("user.hiredOn"),
        "options"
    ];

    const handleSubmit = async function (e, id, password, password2) {

        e.preventDefault()

        const minPwdLength = 4

        if (password.length < minPwdLength) {
            displaySnackbar(slang("Le mot de passe est trop court",
                "The password is too short"), "Min " + minPwdLength + " characters", "warning")
            return
        }

        if (password !== password2) {
            displaySnackbar(slang("Le mot de passe et sa confirmation sont différents",
                "The password and its confirmation are different"), "Min " + minPwdLength + " characters", "warning")
            return
        }


        const url = proxyUrl + "/change_pwd";

        // Logique pour enregistrer

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                id: id,
                password: password,
            }),
        })

        if (response.status === 200) {

            document.getElementById("change_pwd_form").reset()
            displaySnackbar(slang("Mot de passe correctement modifié", "Password changed!"), "Pwd", "success")
        } else {

            displaySnackbar(slang("Une erreur est survenue", "An error occured"), "Pwd", "warning")
        }
    }

    return <div>

        <Header user={props.user} />

        <div className="col-lg-9 mx-auto">


            <AdminPage
                title={slang("Utilisateurs", "Users")}
                endpoint="user"
                CustomForm={UserForm}
                table_titles={table_titles}
                filt1="id"
                filt2="userName"
                filt3="email"
                itemsPerPage={10}
                RowContent={({ elt }) => {
                    return <>
                        <th scope="row">{elt.id}</th>
                        <td>{elt.userName}</td>
                        <td>{elt.email}</td>
                        <td>{format_date_nohour(elt.hiredOn)}</td>
                    </>
                }}
                DetailsSection={({ elt }) => {

                    let password = "";
                    let password2 = "";

                    if (isEmptyObject(elt)) return <></>

                    return <div className="fs-2 bg-light p-3">
                        <h3 className="fs-2 my-3">
                            {slang("Détails ligne ", "Row details id_") + elt.id + " _ " + elt.userName}
                        </h3>

                        {((elt.tel1 !== null) && (elt.tel1 !== "")) &&
                            <p>
                                {elt.tel1}
                                &nbsp;
                                <GetWhatsAppIcon isTelWA={elt.isTel1WA} />
                            </p>
                        }
                        {((elt.tel2 !== null) && (elt.tel2 !== "")) &&
                            <p>
                                {elt.tel2}
                                &nbsp;
                                <GetWhatsAppIcon isTelWA={elt.isTel2WA} />
                            </p>
                        }
                        <p>{"details: " + elt.details} </p>
                        <p className="fs-3"><MyBooleanLabel bool={elt.privSuperuser} myText="privSuperuser" /></p>
                        <p className="fs-3"><MyBooleanLabel bool={elt.privSettings} myText="privSettings" /></p>
                        <p className="fs-3"><MyBooleanLabel bool={elt.privOperations} myText="privOperations" /></p>
                        <p className="fs-3"><MyBooleanLabel bool={elt.privRecordInfo} myText="privRecordInfo" /></p>
                        <p className="fs-3"><MyBooleanLabel bool={elt.privDisplayStats} myText="privDisplayStats" /></p>

                        <form
                            className="row mt-3 py-3 align-items-center"
                            id="change_pwd_form"
                            onSubmit={(e) => handleSubmit(e, elt.id, password, password2)}
                        >

                            <div className="col-6">

                                <div className="form-group mb-3 mt-3">
                                    <label className="text-primary" htmlFor="pwd">Change Password</label>
                                    <input
                                        type="password"
                                        className="form-control fs-3"
                                        name="pwd"
                                        autoComplete="on"
                                        onChange={e => password = e.target.value} />
                                </div>
                                <div className="form-group mb-3 mt-3">
                                    <label className="text-primary" htmlFor="pwd2">Confirmation</label>
                                    <input
                                        type="password"
                                        className="form-control fs-3"
                                        name="pwd2"
                                        autoComplete="on"
                                        onChange={e => password2 = e.target.value} />
                                </div>
                            </div>
                            <div className="col-4">

                                <button
                                    type="submit"
                                    className="btn btn-primary btn-lg fs-3 p-3 my-auto"
                                    value={password}
                                >
                                    {t("general.save")}
                                </button>
                            </div>
                        </form>
                    </div>
                }}
            />

        </div>
    </div>
}

export default UserAdmin;