import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyInput, FormTitleGeneric, handleSubmitGeneric, MyValidationButton } from '../utils/Utils';

const ProductForm = function ({ current_item, refresh_list }) {

    const { t } = useTranslation();

    const bool_modify = Boolean(current_item);

    const [itemToSave, setItemToSave] = useState({
        productName: bool_modify ? current_item.productName : "",
        salePrice: bool_modify ? current_item.salePrice : 0,
        costPrice: bool_modify ? current_item.costPrice : 0,
    })

    const refresh_itemToSave = function (myName, myValue) {

        const newItem = { ...itemToSave, [myName]: myValue }

        // Gestion des cas qui impactent d'autres cases quand on les remplit

        setItemToSave(newItem);
    }

    return <div>
        <FormTitleGeneric bool_modify={bool_modify}
            name={current_item?.productName}
            id={current_item?.id}
        />

        <form className="col-lg-8 col-md-10 mx-auto mb-3"
            onSubmit={(e) => handleSubmitGeneric(e, "product", itemToSave, current_item?.id, refresh_list, bool_modify)}>

            <MyInput
                myName="productName"
                myLabel={t("general.name")}
                myType="text"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={1}
                maxLength={150}
                placeholder={current_item?.productName}
            />

            <MyInput
                myName="salePrice"
                myLabel={t("op.salePrice")}
                myType="Number"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={2}
                func={parseInt}
                placeholder={current_item?.salePrice}
            />
{/* 
            <MyInput
                myName="costPrice"
                myLabel={t("op.costPrice")}
                myType="Number"
                refresh_itemToSave={refresh_itemToSave}
                idGroupe={3}
                func={parseInt}
                placeholder={current_item?.costPrice}
            />
 */}
            {/* Validation */}

            <MyValidationButton bool_modify={bool_modify} />

        </form>

        
    </div>
}

export default ProductForm;