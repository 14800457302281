import { useTranslation } from "react-i18next";
import OpSetForm from "./OpSetForm";
import Header from "../general/Header";
import AdminPage from "../utils/AdminPage";
import { slang } from "../utils/Utils";
import OperationForm from "../operations/OpCategoryForm";

const OpSetAdmin = function (props) {

    const { t } = useTranslation();

    const table_titles = [
        "id",
        t("general.name"),
        // "details",
        "options"
    ];

    const table_titles2 = [
        "id",
        "Code",
        t("op.fullName"),
        "Type",
        "options"
    ];


    return <div>

        <Header user={props.user} />

        <div className="col-lg-9 mx-auto">

            <AdminPage
                title={slang("Les Groupes", "Groups")}
                endpoint="op_set"
                CustomForm={OpSetForm}
                table_titles={table_titles}
                filt1="id"
                filt2="opSetName"
                itemsPerPage={5}
                RowContent={({ elt }) => {
                    return <>
                        <th scope="row" className="text-center">{elt.id}</th>
                        <td className="text-start">{elt.opSetName}</td>
                        {/* <td>{elt.details}</td> */}
                    </>
                }}
                DetailsSection={({ elt }) => {

                    // if (isEmptyObject(elt)) return <></>

                    return <>

                        <AdminPage
                            title={slang("Catégories d'opérations", "Transactions Group")}
                            endpoint="op_category"
                            CustomForm={OperationForm}
                            table_titles={table_titles2}
                            filt1="id"
                            filt2="code"
                            filt3="fullName"
                            itemsPerPage={8}
                            globalFilters={[
                                // titre du filtre, field, function qui renvoie true si l'élt évalué doit s'afficher

                                [t("general.filter") + slang(" Entrées", " Incomes"), "isExpense", (fld) => (fld === 0)],
                                [t("general.filter") + slang(" Dépenses", " Expenses"), "isExpense", (fld) => (fld === 1)],
                                [t("general.filter") + slang(" Groupe", " Groupe") + " " + (elt.opSetName ?? "0"), "idSet", (fld) => (fld === elt.id)],
                                [t("general.filter") + slang(" Groupe", " Groupe") + " " + slang("Autre", "Other"), "idSet", (fld) => (fld === null)],
                            ]}
                            RowContent={({ elt }) => {
                                return <>
                                    <th scope="row" className="text-center">{elt.id}</th>
                                    <td className="text-start">{elt.code}</td>
                                    <td>{elt.fullName + " - " + elt.op_set}</td>
                                    <td>
                                        <p className={elt.isExpense ? "text-danger" : "text-success"}>
                                            <small>
                                                {(elt.isExpense ? slang(" Sortie", " Expense") : slang(" Entrée", " Income"))}
                                            </small>
                                        </p>
                                    </td>
                                </>
                            }}
                        />
                    </>
                }}
            />



        </div>
    </div>
}

export default OpSetAdmin;