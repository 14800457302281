import { useEffect, useState } from 'react';
import { Toast } from 'bootstrap';
import i18next from "i18next";
import { Trans, useTranslation } from "react-i18next";
import { WhatsAppIcon } from "../utils/WhatsAppIcon";

const proxyUrl = require("../../package.json").proxy;


// renvoie le bon texte en fonction de la langue actuelle (français ou anglais)
export const slang = (texte_fr, texte_en) => {

    switch (i18next.language) {
        case "fr":
            return texte_fr

        case "en":
            return texte_en

        default:
            return texte_en
    }
}

// Donne la liste des statuts de vente
export const sale_statuses = [
    [0, slang("En attente", "Waiting")],
    [1, slang("Launched", "Lancée")],
    [2, slang("Ready", "Prête")],
    [3, slang("Delivered", "Livrée")],
    [4, slang("Retrieved", "Récupérée")],
]

export const fcomp = {
    "title": 0,
    "field": 1,
    "func": 2,
}

// permet de savoir si l'utilisateur indiqué dispose du privilège indiqué
export const userHasPriv = (user, idPriv, idDossierClient, idData) => {

    const contains_item = function (idDossierClient, idData) {

        if (!user?.privileges) return false;

        const priv_group_index = user.privileges.findIndex((p) => {
            return ((p.idDossierClient === idDossierClient) && (p.idData === idData))
        })

        if (priv_group_index === -1) return false;

        return user.privileges[priv_group_index].priv.includes(idPriv)
    }

    // (idDossierClient, idData)

    if (idPriv < 10) { // priv digibobo

        return contains_item(0, 0)

    } else if (idPriv < 20) { // priv doss_clt

        return contains_item(idDossierClient, 0)

    } else if (idPriv < 30) { // priv data

        return contains_item(0, idData)

    }
}

export const EmptyComponent = function () {
    return <></>
}

export const MySpinner = ({ show, myText }) => {

    return <div>

        {!show && <></>}

        {show &&
            <div className="d-flex justify-content-center">
                <span className="text-info fs-1">{(myText === undefined) ? "" : (myText + "... ")}</span>
                <div className="spinner-grow text-info" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        }
    </div>
}

export const MyScrollButton = () => {

    return <button className="btn btn-lg scrollButton" onClick={() => window.scrollTo(0, 0)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="brown" className="bi bi-arrow-up-square" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 9.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
        </svg>
    </button>
}

export const my_fetch_post = async (url, body, second_time = false) => {

    try {
        const response = await fetch(proxyUrl + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(body),
        });

        return response

    } catch (error) {
        if (!second_time) {

            return await my_fetch_post(url, body, true) // je lance une 2ème fois parce que les fetch POST envoient d'abord une erreur lors de la toute 1ère tentative
            // L'erreur est liée aux Cors mais je n'ai pas su comment la résoudre
        }
        console.error(error);
        return [];
    }
}

export const my_fetch_get = async (url) => {

    try {

        const response = await fetch(proxyUrl + url);
        const data = await response.json();

        return data

    } catch (error) {

        console.error(error);
        return [];
    }
}

// Composant permettant d'afficher "x élément affiché" avec gestion du pluriel
export const NbItems = ({ count }) => {
    return <span style={{ color: "darkblue" }}>
        &nbsp;

        {count + " "}

        <Trans i18nKey="general.items" count={count}>
            section_texte_simple_id0
        </Trans>
    </span>
}

// scrolle jusqu'à l'élément indiqué en paramètre via son id
export const jump = (elt_id) => {
    var top = document.getElementById(elt_id).offsetTop; //Getting Y of target element
    window.scrollTo(0, top);                        //Go there directly or some transition
}

export const displaySnackbar = function (message, title, severity = "dark") { // For Snackbar

    document.getElementById("toast_title").innerText = title;
    document.getElementById("toast_message").innerText = message;

    const list = document.getElementById("myToast").classList;

    list.replace(list[1], "text-bg-" + severity);

    const toast = new Toast(document.getElementById("myToast")) // No need for options; use the default options

    toast.show();
}

export function format_date(date_string) {

    const date_options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false
    };

    const date_options_same_day = {
        hour: "numeric",
        minute: "numeric",
        hour12: false
    };

    if (!date_string) return ""

    const the_date = new Date(date_string);
    const today = new Date()

    return Intl
        .DateTimeFormat(i18next.language, (today.toDateString() === the_date.toDateString()) ? date_options_same_day : date_options)
        .format(Date.parse(the_date))
}

export function format_date_nohour(date_string) {

    const date_options = {
        year: "numeric",
        month: "short",
        day: "numeric",
    };

    if (!date_string) return ""

    const the_date = new Date(date_string);

    const today = new Date()

    if (today.toDateString() === the_date.toDateString()) {
        return slang("aujourd'hui", "today")
    }

    let result = ""

    try {

        result = Intl
            .DateTimeFormat(i18next.language, date_options)
            .format(Date.parse(the_date))
    } catch (error) {

        return ""
    }

    return result
}

export function format_date_num_nohour(date_string) {

    var d = new Date(date_string),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function digitGroup(num) {
    var numSplit, int, dec, sign, snum;

    if (!num) return 0

    sign = (Math.sign(num) === -1 ? '- ' : '');

    num = Math.abs(num);
    snum = num.toFixed(2);
    numSplit = snum.split('.')

    dec = numSplit[1];

    snum = numSplit[0];
    int = '';

    while (snum.length > 3) {

        int = ' ' + snum.substring(snum.length - 3, snum.length) + int;

        snum = snum.substring(0, snum.length - 3)
    }

    int = snum + int;

    return <span style={{ whiteSpace: "nowrap" }}>
        {sign + int + ((dec !== '00') ? ',' + dec : '')}
    </span>
}

export const FormTitleGeneric = function ({ bool_modify, name, id }) {

    const { t } = useTranslation();

    return <h2 className="text-center mb-3">
        {bool_modify ?
            <>
                {t("general.modify")}
                <span className="fw-light">
                    {" " + name + " id_" + id}
                </span>
            </>
            : t("general.add")}
    </h2>
}

export const FormTitleRelated = function ({ name, id }) {

    const { t } = useTranslation();

    return <h3 className="text-center mb-3">

        <>
            {t("general.related")}
            <span className="fw-light">
                {" " + name + " id_" + id}
            </span>
        </>
    </h3>
}

export const handleSubmitGeneric = async function (e, endpoint, itemToSave, item_id = 0, refresh_list, bool_modify) {

    e.preventDefault();

    const url = "/" + (!bool_modify ? endpoint : endpoint + "/" + item_id);

    // Logique pour enregistrer

    displaySnackbar(slang("Veuillez patienter...", "Please wait..."), "Wait", "info")

    const response = await my_fetch_post(url, itemToSave)

    if (response.status === 200) {

        displaySnackbar(slang("Modification effectuée", "Saved succesfully"), "OK", "success")
        refresh_list()
    } else {

        displaySnackbar(slang("Modification impossible", "An error occured"), "ERROR", "warning")
    }

};

export const ShowObject = function ({ content }) {
    return <pre className="px-3 mx-3 fs-2 text-secondary" style={{ marginTop: "50px" }}>
        <code>
            {JSON.stringify(content, null, '  ')}
        </code>
    </pre>
}

export const GetWhatsAppIcon = function ({ isTelWA }) {

    if (!isTelWA) return <></>

    return <WhatsAppIcon size={20} />
}

function isEmpty(obj) {
    for (const prop in obj) {
        if (Object.hasOwn(obj, prop)) {
            return false;
        }
    }

    return true;
}

export function isEmptyObject(value) {
    if (value == null) {
        // null or undefined
        return false;
    }

    if (typeof value !== 'object') {
        // boolean, number, string, function, etc.
        return false;
    }

    const proto = Object.getPrototypeOf(value);

    // consider `Object.create(null)`, commonly used as a safe map
    // before `Map` support, an empty object as well as `{}`
    if (proto !== null && proto !== Object.prototype) {
        return false;
    }

    return isEmpty(value);
}

export function MySelect({ myLabel, myName, refresh_itemToSave, idGroupe, data, func = null }) {

    // data : 0=id 1=text 2=defaultvalue

    const { t } = useTranslation();

    let default_value_filled = false;

    const getDefaultValue = function () {
        const find_result = data.find(obj => obj[2] === true)

        return (find_result) ? find_result[0] : "none";
    }

    return <div className="form-group" style={{ marginTop: "30px" }}>
        <div className="input-group mb-3">
            <div className="input-group-prepend col-sm-3">
                <label className="input-group-text fs-2" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
            </div>
            <select
                id={"inputGroup" + idGroupe}
                className="custom-select fs-2 col-sm-9"
                name={myName}
                defaultValue={getDefaultValue()}
                onChange={(e) => {
                    if (func) {

                        refresh_itemToSave(myName, func(e.target.value))

                    } else {

                        refresh_itemToSave(myName, e.target.value)
                    }
                }}
            >

                {data.map((elt, idx) => {
                    if (!default_value_filled) {
                        default_value_filled = elt[2];
                    }
                    return <option key={idx} value={elt[0]}>{elt[1]}</option>
                })}

                {!default_value_filled && <option value="none" disabled hidden>{t("general.select_default")}</option>}
            </select>
        </div>
    </div>
}

export function MySelectMultiple({ myLabel, myName, refresh_itemToSave, idGroupe, data, func = null }) {

    // data : 0=id 1=text 2=defaultvalue

    const { t } = useTranslation();

    function getSelectValues(select) {
        var result = [];
        var options = select && select.options;
        var opt;

        for (var i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];

            if (opt.selected) {
                if (func) {

                    result.push(func(opt.value || opt.text));
                } else {

                    result.push(opt.value || opt.text);
                }
            }
        }
        return result;
    }

    return <div className="form-group mb-3">
        <div className="input-group mb-1">
            <label className="input-group-text fs-2 col-sm-3" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>

            <select
                id={"inputGroup" + idGroupe}
                className="form-select fs-2 col-sm-9"
                name={myName}
                multiple={true}
                onChange={(e) => {
                    refresh_itemToSave(myName, getSelectValues(e.target))
                }}
            >

                {data.map((elt, idx) => {

                    return <option key={idx} value={elt[0]}>{elt[1]}</option>
                })}

                <option value="none" disabled hidden>{t("general.select_default")}</option>
            </select>
        </div>
    </div>
}

export function MySelectSearch({ classInfo = "fs-2 btn btn-outline-dark", myName, myDefaultValue = "none", refresh_itemToSave, idGroupe, myLabel, data, func = null, withExpand = true }) {

    const [expand, setExpand] = useState(true) // indique si le MySelectSearch est affiché
    const [text, setText] = useState("")

    const { t } = useTranslation();

    const [dataFilter, setDataFilter] = useState("");
    const [currentValue, setCurrentValue] = useState(myDefaultValue);

    useEffect(() => {

        if ((myDefaultValue === "none") || (myDefaultValue === 0)) {
            setExpand(true)
        } else {
            setExpand(false)
            setCurrentValue(myDefaultValue)
            setText(myLabel + " " + myDefaultValue)
        }

    }, [myDefaultValue, myLabel])

    const compose_data = function () {
        // Permet de respecter le filtre actif

        if (dataFilter.length < 2) return data

        return data.filter((d) => {
            return d[1].toLowerCase().includes(dataFilter.toLowerCase())
        })
    }

    if (withExpand) {

        if (!expand) return <p className={"my-3 me-3 " + classInfo} onClick={() => {
            setExpand(true)
        }} >{text}</p>
    }

    const makeExpandOff = function () {
        const elts_select = document.getElementById("inputGroup" + idGroupe).options

        const idx = elts_select.selectedIndex

        if (idx > -1) setText(elts_select[idx].text)

        setExpand(false)
    }

    return <div className="form-group mb-3" style={{ marginTop: "30px" }}>
        <div className="input-group mb-1">
            <label className="input-group-text fs-2" htmlFor={"inputGroup" + idGroupe} onClick={(e) => {
                if (!withExpand) return
                e.preventDefault()

                setExpand(false)
            }}>{myLabel}</label>

            <input
                type="text"
                className="form-control fs-2"
                style={{ width: "150px" }}
                value={dataFilter}
                placeholder={"search"}
                onChange={(e) => {
                    setDataFilter(e.target.value)
                }} />

            <button className="form-control fs-4" style={{ maxWidth: "50px" }} type="button" onClick={(e) => { setDataFilter("") }}>X</button>

            <select
                id={"inputGroup" + idGroupe}
                className="form-select fs-2"
                name={myName}
                value={currentValue}
                onChange={(e) => {

                    e.preventDefault()

                    if (e.target.value !== "none") makeExpandOff()

                    if (func) {

                        if (e.target.value === "none") {

                            setCurrentValue(0)
                            refresh_itemToSave(myName, func(0))
                        } else {

                            setCurrentValue(func(e.target.value))
                            refresh_itemToSave(myName, func(e.target.value))
                        }

                    } else {

                        setCurrentValue(e.target.value)
                        refresh_itemToSave(myName, e.target.value)
                    }
                }}
            >

                <option value="none">{t("general.select_default")}</option>

                {compose_data().map((elt, idx) => {

                    return <option key={idx} value={elt[0]}>{elt[1]}</option>
                })}

            </select>
        </div>

        {(dataFilter.length > 1) && <div className="btn-group-vertical mb-3 fs-2" role="group" aria-label="Buttons to select an item">

            {compose_data().map((elt, idx) => {
                return <button className="btn btn-lg fs-2 btn-outline-primary" key={idx} type="button" onClick={(e) => {

                    setDataFilter("")

                    document.getElementById("inputGroup" + idGroupe).value = elt[0]

                    makeExpandOff()

                    if (func) {

                        setCurrentValue(func(elt[0]))
                        refresh_itemToSave(myName, func(elt[0]))
                    } else {

                        setCurrentValue(elt[0])
                        refresh_itemToSave(myName, elt[0])
                    }

                }}>{elt[1]}</button>
            })}
        </div>}
    </div>
}

export const MySpinningSelectSearch = function ({ spinnerText, searchRefreshItemToSave, searchName, searchIdGroup, searchLabel, searchEndPoint }) {

    const [data, setData] = useState(null);

    // fetch Data
    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(proxyUrl + '/' + searchEndPoint);
                const data = await response.json();
                setData(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();

    }, [searchEndPoint])

    return <>

        {!data &&
            <MySpinner show={true} myText={spinnerText} />
        }

        {data &&
            <MySelectSearch
                myName={searchName}
                refresh_itemToSave={searchRefreshItemToSave}
                idGroupe={searchIdGroup}
                myLabel={searchLabel}
                data={data.map((d) => ([d.id, d.optionName]))}
                func={parseInt}
            />
        }
    </>
}

export function MyInput({ myLabel, myName, myValue, refresh_itemToSave, idGroupe, myType, step = 1, func = null, maxLength = 300, placeholder }) {

    return <div className="form-group" style={{ marginTop: "30px" }}>
        <div className="input-group mb-3">
            <div className="input-group-prepend col-sm-4">
                <label className="input-group-text fs-2" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
            </div>
            <input
                id={"inputGroup" + idGroupe}
                className="form-control fs-2 col-sm-8"
                name={myName}
                type={myType}
                step={step}
                defaultValue={myValue}
                maxLength={maxLength}
                placeholder={placeholder}
                onChange={(e) => {
                    if (func) {

                        refresh_itemToSave(myName, func(e.target.value))
                    } else {

                        refresh_itemToSave(myName, e.target.value)
                    }
                }}
            />
        </div>
    </div>
}

export function MyTextArea({ myLabel, myName, myValue, refresh_itemToSave, idGroupe, maxLength = 1000 }) {

    return <div className="form-group" style={{ marginTop: "30px" }}>
        <div className="input-group mb-3">
            <div className="input-group-prepend col-sm-3">
                <label className="input-group-text fs-2" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
            </div>
            <textarea
                id={"inputGroup" + idGroupe}
                className="form-control fs-2 col-sm-9"
                name={myName}
                rows={4}
                defaultValue={myValue}
                maxLength={maxLength}
                onChange={(e) => {
                    refresh_itemToSave(myName, e.target.value)
                }}
            />
        </div>
    </div>
}

export function MyInputSearch({ myLabel, idGroupe, items, setItemsFiltered, filt1, filt2, filt3 }) {

    return <div className="input-group" style={{ marginTop: "30px" }}>
        {myLabel.length > 0 &&
            <label className="input-group-text fs-2" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
        }

        <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">

                <img style={{ height: "40px", width: "auto" }} src="/images/search.svg" alt="Crayon" />

            </span>
        </div>

        <input
            id={"inputGroup" + idGroupe}
            className="form-control fs-2"
            style={{ maxWidth: "255px" }}
            type="search"
            placeholder={slang("recherche", "search")}
            onChange={(e) => {

                let filt = [];

                if (e.target.value.length === 0) {

                    filt = items
                } else {

                    filt = items.filter((t) => {

                        let filt_elt = ""

                        if (filt1) filt_elt += t[filt1]
                        if (filt2) filt_elt += t[filt2]
                        if (filt3) filt_elt += t[filt3]

                        return filt_elt.toLowerCase().includes(e.target.value.toLowerCase())
                    })
                }

                setItemsFiltered(filt)
            }} />
    </div>
}

export function MySwitch({ myLabel, myName, refresh_itemToSave, idGroupe }) {
    return <div className="form-check form-switch">

        <label className="form-check-label fs-2" htmlFor={"Switch" + idGroupe}>{myLabel}</label>

        <input className="form-check-input"
            type="checkbox"
            name="Switch"
            role="switch"
            id={"Switch" + idGroupe}
            onChange={(e) => {

                refresh_itemToSave(myName, e.target.checked)
            }}
        />

    </div>
}

export function MyCheckBox({ myLabel, myName, refresh_itemToSave, idGroupe }) {
    return <div className="form-check col mb-3 pb-3" style={{ marginTop: "30px" }}>
        <div className="row border-bottom">

            <div className="col-5">
                <label className="font-italic fs-2" htmlFor={"ON" + idGroupe}>{myLabel}</label>
            </div>

            <div className="col-4">

                <input className="form-check-input fs-2"
                    type="checkbox"
                    name="ON"
                    onChange={(e) => {

                        document.getElementById("OFF" + idGroupe).checked = false;
                        refresh_itemToSave(myName, e.target.checked)
                    }}
                    id={"ON" + idGroupe} />
                <label className="form-check-label fs-3" htmlFor={"ON" + idGroupe}>
                    ON
                </label>
            </div>

            <div className="col-3">

                <input className="form-check-input fs-2"
                    type="checkbox"
                    name="OFF"
                    onChange={(e) => {
                        document.getElementById("ON" + idGroupe).checked = false;
                        refresh_itemToSave(myName, false)
                    }}
                    id={"OFF" + idGroupe} />
                <label className="form-check-label fs-3" htmlFor={"OFF" + idGroupe}>
                    OFF
                </label>
            </div>
        </div>
    </div>
}

export function MyImage({ myLabel, setImage, idGroupe }) {
    return <div className="input-group">
        <div className="input-group-prepend">
            <label className="input-group-text" htmlFor={"inputGroup" + idGroupe}>{myLabel}</label>
        </div>
        <input
            id={"inputGroup" + idGroupe}
            type="file"
            accept=".jpg"
            onChange={(e) => setImage(e.target.files[0])}
        />

        <button className="btn btn-lg fs-2 btn-sm btn-danger" type="button" onClick={(e) => { e.preventDefault(); setImage(null) }}>Reset Image</button>
    </div >
}

export function MyImagePreview({ image, base_url, id_image, bln_modify }) {

    // Gestion de l'URL de l'image
    const getImageURL = () => {
        if (!image && bln_modify) {

            return base_url.replace("id_image", id_image)
        }

        return URL.createObjectURL(image)
    }

    return <>
        {(image || bln_modify) &&
            <img style={{ maxHeight: "300px" }} src={getImageURL()} alt="élément / Item" />
        }
    </>
}

export function MyValidationButton({ bool_modify }) {

    const { t } = useTranslation();

    return <button
        className="btn btn-lg btn-success fs-2 mt-3 p-3"
        type="submit"
    >
        {t(bool_modify ? "general.modify" : "general.add")}
    </button>
}

export function MyBooleanLabel({ bool, myText = "" }) {

    return <span className={bool ? "text-success" : "text-danger"}>{myText} {bool ? slang('O', 'Y') : 'N'}</span>
}


/*

// Composant permettant d'enregistrer une commande et de se rediriger vers whatsapp
export const OrderForm = ({ total, content }) => {

    const { t } = useTranslation()
    const [phone, setPhone] = useState("")
    const [confirmOrder, setConfirmOrder] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)

    const create_order = async () => {

        if (phone.length === 0) {

            document.getElementById("inputGroup1").focus()
            displaySnackbar(
                slang("Svp veuillez indiquer votre numéro de téléphone", "Please fill your phone number"),
                slang("Numéro de téléphone requis", "Phone number is required"),
                "warning")
            return
        }

        try {

            await my_fetch_POST(proxyUrl + "/order", {
                phone: phone,
                total: total,
                content: content,
            })

            // Pour le suivi de la conversion Google
            window.gtag('event', 'conversion', { 'send_to': 'AW-756128754/9GWmCKegqI8ZEPK3xugC' })

            setShowSpinner(true)

            document.getElementById("redirect_to_whatsapp").click()

            setTimeout(() => {
                setShowSpinner(false)
            }, 2000);


        } catch (error) {
            console.error(error);
        }
    }

    const refresh_selection = (name, value) => {
        setPhone(value)
    }

    return <div className="row m-1">

        {!showSpinner &&
            <>

                <a href={"https://wa.me/237678532492/?text=order_" + phone} hidden id="redirect_to_whatsapp">whatsapp</a>

                <button
                    className={"btn w-75 mx-auto mb-3 fs-4 btn-" + (confirmOrder ? "outline-dark border-gray" : "success")}
                    onClick={() => setConfirmOrder(!confirmOrder)}
                >
                    {t("utils.btn_order")}
                </button>

                {confirmOrder &&
                    <>
                        <MyInput
                            myName="phone"
                            refresh_itemToSave={refresh_selection}
                            idGroupe={1}
                            myLabel={t("utils.phone")}
                            myType="text"
                        />

                        <button className="btn fs-4 btn-success" onClick={create_order}>{t("utils.btn_confirm_order")}</button>
                    </>
                }
            </>
        }

        <MySpinner show={showSpinner} />
    </div>
}

*/
